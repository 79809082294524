// Helpers
import { getRequest, postRequest, privateApiUrl } from 'api/v3';
import { createMap } from 'common/utils/helpers';

// Utils
import { userAuthenticated } from 'ideation/utils/userAuthenticate';

export function getIdeasUrl(ideaBoardId, params) {
  return privateApiUrl(`idea_boards/${ideaBoardId}/ideas`, params);
}

function getIdeasUserActions(ideaBoardId, ideaIds) {
  return getRequest(
    privateApiUrl(`idea_boards/${ideaBoardId}/idea_user_actions`, {
      include: ['idea_votes', 'moderation_flags'],
      idea_ids: ideaIds,
    })
  );
}

/**
 * Fetch idea with vote and moderation flag data
 * @param {string} url
 * @param {string} ideaBoardId
 * @returns {object}
 */
export async function getIdeaFetcher(url, ideaBoardId) {
  const ideaResponse = await getRequest(url);

  if (!userAuthenticated()) return ideaResponse;

  const { deserialized: idea } = ideaResponse;
  const { deserialized: userActions } = await getIdeasUserActions(ideaBoardId, [idea.id]);
  const ideaVotesMap = createMap(userActions.ideaVotes, 'ideaId');
  const moderationFlagsMap = createMap(userActions.moderationFlags, 'ideaId');

  if (idea.id in ideaVotesMap) {
    idea.votes = ideaVotesMap[idea.id];
  }

  if (idea.id in moderationFlagsMap) {
    idea.moderationFlags = moderationFlagsMap[idea.id];
  }

  return ideaResponse;
}

export async function getIdeasFetcher(url, ideaBoardId) {
  const ideasResponse = await getRequest(url);

  if (!userAuthenticated()) return ideasResponse;

  const { deserialized: ideas } = ideasResponse;
  const ideaIds = ideas.map((idea) => idea.id);

  const { deserialized: userActions } = await getIdeasUserActions(ideaBoardId, ideaIds);
  const ideaVotesMap = createMap(userActions.ideaVotes, 'ideaId');
  const moderationFlagsMap = createMap(userActions.moderationFlags, 'ideaId');

  for (const idea of ideas) {
    if (idea.id in ideaVotesMap) {
      idea.votes = ideaVotesMap[idea.id];
    }

    if (idea.id in moderationFlagsMap) {
      idea.moderationFlags = moderationFlagsMap[idea.id];
    }
  }

  return ideasResponse;
}

export function getIdeaUrl(id, params) {
  return privateApiUrl(`ideas/${id}`, params);
}

export function createIdea(ideaBoardId, { title, description, image, force }) {
  return postRequest(privateApiUrl(`idea_boards/${ideaBoardId}/ideas`), {
    data: { type: 'idea', attributes: { title, description, image, force } },
  });
}

export function upvoteIdea(id) {
  return postRequest(privateApiUrl(`ideas/${id}/upvote`), { data: { type: 'upvote' } });
}

export function downvoteIdea(id) {
  return postRequest(privateApiUrl(`ideas/${id}/downvote`), { data: { type: 'downvote' } });
}

export function flagIdea(id, { reason }) {
  return postRequest(privateApiUrl(`ideas/${id}/moderation_flag`), {
    data: { type: 'moderation_flag', attributes: { reason } },
  });
}

/**
 * Get discussions of the idea
 * @param {(number|string)} discussionTopId
 * @param {number} [pageNumber=1]
 * @param {number} [pageSize=25]
 * @param {string} [sort=-created_at]  - most_popular, -created_at, created_at
 * @param {object} filter
 * @param {boolean} [filter.approved=true]
 * @param {boolean} [filter.require_moderation=false]
 * @returns {string}
 */
export const getIdeaDiscussionsURL = (
  discussionTopId,
  pageNumber = 1,
  pageSize = 25,
  sort = '-created_at',
  filter = {
    require_moderation: false,
  }
) => {
  const params = {
    page: {
      number: pageNumber,
      size: pageSize,
    },
    sort,
    filter,
  };

  return privateApiUrl(`ideas/${discussionTopId}/discussions`, params);
};

/**
 * Export comments for a Idea
 * @param {(string|number)} ideaId
 * @param {string} defaultLocale
 * @returns
 */
export const exportIdeaComments = (ideaId, defaultLocale) => {
  const url = privateApiUrl(`ideas/${ideaId}/export_discussions`);

  return postRequest(url, {
    data: {
      type: 'export_discussions',
      attributes: {
        ...(defaultLocale && { defaultLocale }),
      },
    },
  });
};
